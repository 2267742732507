/* Change this file to get your personal Porfolio */

const greeting = {
  /* Your Summary And Greeting Section */
  title: "Hi all 👋 I'm Ali",
  subTitle:
    "A passionate Full Stack Software Developer 🚀 having an experience of building Web and Mobile applications with JavaScript / Reactjs / Nodejs / React Native and some other cool libraries and frameworks",
  resumeLink:
    "https://drive.google.com/file/d/1BPoPn27wo8wBUSJcrKFKFqf1t3rTGPHf/edit"
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/realabbas",
  linkedin: "https://www.linkedin.com/in/ali-abbas-19b630161/",
  gmail: "ali@mrroom.in",
  gitlab: "https://gitlab.com/realabbas",
  quora: "https://www.quora.com/ali-abbas-1224/",
  dev: "https://dev.to/realabbas"
};

const skillsSection = {
  /* Your Skills Section  */
  title: "What i do",
  subTitle: "CRAZY FULL STACK DEVELOPER WHO WANT TO EXPLORE EVERY TECH STACK",
  skills: [
    "⚡ Develop highly interactive Front end / User Interfaces for your mobile and web applications",
    "⚡ Progressive Web Applications ( PWA ) in normal and SPA Stacks",
    "⚡ Integration of third party services such as  AWS / Digital Ocean / Linode",
    "⚡ Building of Production Level Restful API and Command Line Tools"
  ],

  /* Make Sure You include correct Font Awesome Classname to view your icon */
  /* https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "java",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    },
    {
      skillName: "php",
      fontAwesomeClassname: "fab fa-php"
    }
  ]
};

const openSource = {
  /* Your Open Source Section to View Your Github Pinned Projects */
  /* To know how to get github key look at readme.md */

  githubConvertedToken:
    "OWI2MjIyZDIyNGVkMzc3MWY2N2RiZDJkNjBlOGFiZDk2MzYyYzkyZg==",
  githubUserName: "realabbas"
};

const bigProjects = {
  /* Some Big Projects You have worked with your company */
  title: "Big Projects",
  subtitle: "SOME STARTUPS AND COMPANIES THAT I HELP TO CREATE THEIR TECH",
  projects: [
    {
      image: "https://d33wubrfki0l68.cloudfront.net/0498d63c5cebdf18681f2e243c859b5e65d2242f/7140f/images/logo.png",
      link: "https://mrroom.in/"
    },
    {
      image:
        "https://www.mrroom.in/images/mrroom%20no%20brokerage%20hostel%20app.gif",
      link: "https://play.google.com/store/apps/details?id=com.kakarooms"
    },
  ]
};

const achievementSection = {
  /* Your Achievement Section Include Your Certification Talks and More */

  title: "Achievements And Certifications 🏆 ",
  subtitle:
    "Achievements, Certifications Award Letters and Some Cool Stuff that i have done !",

  achivementsCards: [
    {
      title: "IB Hubs Startup School",
      description:
        "Founded Startup was among the 25 selected startups all over the India for iBHubs Startup School 2k19 under the mentorship of Mr. Abhinav Pandey and Mr. Avinash Thavva",
      image:
        "https://api.startupindia.gov.in/sih/api/file/user/image/Incubator?fileName=70c0b98d-b67b-4140-8ca0-4244af006151.jpg",
      footerLink: [
        {
          name: "Certification",
          url:
            "https://media-exp1.licdn.com/media-proxy/ext?w=1080&h=1920&f=n&hash=IhsFkm68%2Bj8XlMGxLKW6Hr3691E%3D&ora=1%2CaFBCTXdkRmpGL2lvQUFBPQ%2CxAVta5g-0R6jnhodx1Ey9KGTqAGj6E5DQJHUA3L0CHH05IbfPWjufpaKKrf090ARengFjQAzeeq1STPnGo7oeYnped8kipfjI8L5aRUPbhU4hGUB_N88"
        }
      ]
    },
    {
      title: "Startuptalky",
      description:
        "Developed a Google Assistant Action JavaScript Guru that is available on 2 Billion devices world wide.",
      image: "https://static.startuptalky.com/2019/02/StartupTalky_logo.png",
      footerLink: [
        {
          name: "Listed in the Successful Startups of Kanpur",
          url:
            "https://startuptalky.com/kanpur-startups/#kanpur_startups_mrroom"
        }
      ]
    },

    {
      title: "Hactoberfest",
      description: "Completed Hacktoberfest with 20+ PR",
      image:
        "https://hacktoberfest.digitalocean.com/assets/logo-hf19-full-10f3c000cea930c76acc1dedc516ea7118b95353220869a3051848e45ff1d656.svg",
      footerLink: [
        {
          name: "Completed Hacktoberfest with 20+ PR",
          url:
            "https://media-exp1.licdn.com/dms/image/C5122AQEhkvuEnfgDMw/feedshare-shrink_1280/0?e=1584576000&v=beta&t=1p5_kXz5jmAIcplzt2HfVbMsPu9Gvij3rgPR4xF_tZc"
        }
      ]
    }
  ]
};

const blogSection = {
  /* Blogs Section */

  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff. I love to write and spread knowledge what i have learned",

  blogs: [
    {
      url: "https://www.quora.com/profile/Ali-Abbas-1224",
      image:
        "https://cdn.dnaindia.com/sites/default/files/styles/full/public/2018/12/04/761061-quara.jpg",
      title: "900K+ Answer Views on Quora.com",
      description: "Writing about technology and other experiences"
    },
    {
      url: "https://medium.com/@saadpasta/why-react-is-the-best-5a97563f423e",
      image:
        "https://thepracticaldev.s3.amazonaws.com/i/g355ol6qsrg0j2mhngz9.png",
      title: "Technical Blog at Dev.to with 100K+ Views and 6K+ Followers",
      description:
        "From just a language to making interactive pages, it has evolved to a whole new lev where servers are built using Javascript. In other words Javascript is everywhere. There are many developers and engineers involved in this entire long process of development, one of them is offcourse Ryan Dahl."
    }
  ]
};

// Talks Sections

const talkSection = {
  title: "INTERVIEWS",
  subtitle: "I LOVE TO SHARE MY EXPERIENCE AND MEET PEOPLE WITH ENTREPRENEURIAL MINDSET😅",

  talks: [
    {
      title: "Startup Media Interview",
      subtitle: "Media Vyapaarjagat.com",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.vyapaarjagat.com/startup/kanpur-based-startup-mr-room-helping-in-simplifying-the-process-of-searching-for-rental-rooms/",
      image:
        "https://www.vyapaarjagat.com/wp-content/uploads/2019/09/ibHubs-MrRoom-Your-Instant-Room-Partner.jpg"
    }
  ]
};

// Podcast Section
const podcastSection = {
  title: "Podcast 🎙️",
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ]
};

const contactInfo = {
  title: "Contact Me ☎️",
  subtitle: "Discuss a project or just want to say hi my inbox is open for all",
  number: "+91-9140888233",
  email_address: "ali@mrroom.in"
};

export {
  greeting,
  socialMediaLinks,
  skillsSection,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo
};
